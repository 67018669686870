.moduletable {
	&.sketch_dropdown {
		background: $bgbox;
		margin-bottom: 24px;
		h3.page-header {
			padding: 6px 6px 0px;
			@media #{$large}{
				padding: 12px 12px 0px;
			}
			margin-bottom: 12px;
		}
		ul.nav {
			margin-left: 0;
			padding-bottom: 6px;
			li {
				list-style: none;
				position: relative;
				a {
					display: block;
					padding-left: 6px;
					padding-right: 6px;
					padding-top: 3px;
					padding-bottom: 3px;
					color: $base;
					&:hover {
						color: $link;
					}
					@media #{$large}{
						padding-left: 12px;
						padding-right: 12px;
					}
					border-bottom: 1px solid $border;
				}
				ul {
					margin-left: 0;
					background: darken(#fff, 10%);
				}
			}
		}
		span.toggl0r {
			position: absolute;
			right: 6px;
			@media #{$large}{
				right: 12px;
			}
			padding: 3px;
			padding-top: 0;
			cursor: pointer;
			padding-bottom: 0;
			color: $base;
			transform: rotate(90deg);
			transition: .3s all ease 0s;
			&:before {
				content: '>';
				font-family: monospace;
			}
			&.active {
				transform: rotate(-90deg);
			}
		}
	}
}
