#login-form{
	.userdata{
		label{
			@include font-size(14,1.5);
			min-width: 148px;
			width: 100%;
			display: inline-block;
		}
		input{
			border: 1px solid grey;
			@include font-size(14,1.5);
			width: 100%;
		}
		ul.unstyled{
			margin-bottom: 0;
			li{
				margin-bottom: 0;
				@include font-size(14,1.5);
				a{
					@include font-size(14, 1.5);
				}
			}
		}
	}
	button[type="submit"]{
		margin-bottom: 0;
		&:before{
			content: $icon-user;
			font-family: $icon;
			padding-right: 6px;
		}
	}
	.login-greeting {
		margin-bottom: 12px;
	}
}
.login, .profile, .profile-edit, .logout, .reset, .remind, .reset-confirm, .reset-complete {
	@include clear();
	ul.btn-toolbar{
		list-style: none;
		margin-left: 0;
		li{
			margin-right: 12px;
			margin-bottom: 12px;
			display: inline-block;
			&:last-child{
				margin-right: 0;
			}
		}
	}
	form.form-validate.form-horizontal.well{
		width: 350px;
		max-width: 100%;
	}
	.control-group, dl{
		width: 100%;
		float: left;
		clear: both;
		margin-bottom: 6px;
		.control-label, .controls, dd, dt{
			width: 100%;
			float: left;
			display: block;
			margin-bottom: 6px;
			@media #{$medium}{
				width: 50%;
			}
		}
	}
	fieldset{
		padding-top: 12px;
		legend{
			padding: 0 6px;
			font-size: 14px;
			font-weight: bold;
			font-family: $base-font-family;
		}
	}
	.chzn-container * {
		font-family: $base-font-family;
	}
}
ul.nav.nav-tabs.nav-stacked {
	float: left;
	margin-bottom: 6px;
}
.moduletable.login{
	ul{
		float: left;
		width: 100%;
	}
	.login .control-group .controls{
		width: 100% !important;
	}
}