body {
	.formResponsive {
		input[type="submit"], button[type="submit"] {
			@include button();
			@include font-size(16);
		}
		strong.formRequired {
			display: none;
		}
		select{
			border-radius: 3px;
		}
		input, textarea{
			width: 220px;
		}
		input[type=radio], input[type=checkbox]{
			width: auto;
		}
		strong.formRequired {
			position: absolute;
			right: 24px;
			transform: translateY(25px);
		}
		input[type='radio'], input[type='checkbox']{
			filter: grayscale(1);
		}
		span.formValidation {
			display: block;
			margin-bottom: 24px;
		}
		.formBody{
			margin-bottom: 12px;
		}
	}
	.formResponsive select, .formResponsive textarea, .formResponsive input[type="text"], .formResponsive input[type="number"], .formResponsive input[type="email"], .formResponsive input[type="tel"], .formResponsive input[type="url"], .formResponsive input[type="password"]{
		@include font-size(14);
		padding: 12px;
		box-sizing: border-box;
		max-height: none;
		height: auto;
		margin-bottom: 0;
		min-width: 278px;
	}
	.formResponsive input[type=email]:focus, .formResponsive input[type=number]:focus, .formResponsive input[type=password]:focus, .formResponsive input[type=tel]:focus, .formResponsive input[type=text]:focus, .formResponsive input[type=url]:focus, .formResponsive textarea:focus{
		border-color: $link;
		box-shadow: none;
	}
	.yui-calendar .calweekdaycell {

		color: $light;
	}
	.yui-calendar td.calcell.calcellhover{
		background: $link;
		color: $base;
		border-color: $link;
	}
	.yui-calendar .calnavleft, .yui-calendar .calnavright{
		filter: grayscale(1);
	}
	.formResponsive button[type=button].rsform-button-next{
		@include button();
		margin-left: 48px;
	}
	.formResponsive button[type=button].rsform-button-prev, .formResponsive input[type=button].rsform-button-prev{
		@include button();
	}
	.formResponsive .rsformProgressContainer .rsformProgressBar {
		background-color: $link;
	}
	.formResponsive select.rsform-select-box-small {
		width: 88px;
		min-width: 88px;
	}
	.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current, .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_default, .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box>div>div.xdsoft_current{
		background: $link;
		box-shadow: $link 0 1px 3px 0 inset;
	}
	.formResponsive select:focus{
		border-color: $link;
		box-shadow: none;
	}
	.xdsoft_datetimepicker .xdsoft_calendar td:hover, .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box>div>div:hover {
		color: #fff!important;
		background: $link!important;
		box-shadow: none!important;
	}
	.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_today{
		font-weight: bold;
		color: $main;
	}
	.formResponsive .formControls .formBody .rsformgrid6{
		margin-bottom: 24px;
	}
	label.formControlLabel {
		font-weight: bold;
		margin-bottom: 12px;
		display: block;
	}
}
