// Disable Frontend Module Editing
a.btn.jmodedit {
	display: none !important;
}

.content_fixed_right {
	right: -4px;
	.moduletable {
		right: 0;
		margin-right: -320px;
		transition: .3s right ease 0s;
		&.active {
			right: 320px;
		}
		> h3 {
			right: 100%;
			transform: rotate(-90deg);
			transform-origin: 100% 100%;
			box-shadow: -2px -4px 10px -7px #333;
		}
	}
}

.content_fixed_left {
	left: -4px;
	.moduletable {
		left: 0;
		margin-left: -320px;
		transition: .3s left ease 0s;
		&.active {
			left: 320px;
		}
		> h3 {
			left: 100%;
			transform: rotate(90deg);
			transform-origin: 0 100%;
			box-shadow: -5px -4px 10px -7px #333;
		}
	}
}

.content_fixed_right, .content_fixed_left {
	position: fixed;
	z-index: 999;
	.moduletable {
		width: 320px;
		position: relative;
		margin-bottom: 24px;
		box-shadow: $box-shadow;
		> h3 {
			background: $light;
			cursor: pointer;
			margin-bottom: 0;
			display: inline-block;
			padding-left: 12px;
			padding-right: 12px;
			border-radius: 10px 10px 0 0;
			top: -32px;
			height: 32px;
			position: absolute;
			margin-right: -1px;
			letter-spacing: 1px;
			text-transform: uppercase;
			color: $link;
			padding-top: 6px;
			line-height: 17px;
		}
		> div, > ul.nav {
			background: $light;
			border-radius: 0 0 0 $br;
			min-height: 200px;
			box-shadow: 5px 5px 13px -2px #333;
			padding: 12px;
		}
	}
}

.content_main_right {
	.moduletable {
		@include bgblock();
	}
}

.moduletable {
	&.news {
		> h2 {
			text-align: center;
		}
		padding-top: 48px;
		padding-bottom: 48px;
		.newsflashBox {
			box-shadow: 0px 3px 6px #00000029;
			border-radius: 36px;
			overflow: hidden;
			position: relative;
			padding-bottom: 48px;
			height: 100%;
			transition: all .3s cubic-bezier(.2, .5, .3, 1);
			background: $light;
			&:hover {
				transform: translateY(-5px);
				box-shadow: 0 20px 45px rgb(0 0 0 / 12%);
			}
			figure {
				margin: 0;
				img {
					display: block;
				}
			}
			.textBlock {
				padding: 24px;
				padding-bottom: 0;
			}
			a.readmore {
				position: absolute;
				bottom: 12px;
			}
		}
	}
	&.preisboxen {
		padding-top: 24px;
		> h3{
			text-align: center;
		}
		.custom.preisboxen {
			max-width: 100%;
			width: 750px;
			margin:  0 auto;
			padding-bottom: 72px;
			.wf-columns{
				margin-top: 72px;
				.wf-column{
					text-align: center;
					padding: 12px;
					box-shadow: 0 7px 10px -5px #333;
					transition: all .3s cubic-bezier(.2,.5,.3,1);
					p{
						margin-bottom: 6px;
					}
					&:nth-child(2){
						border: 4px solid $link;
						background: $light;
						z-index: 1;
						@media #{$large}{
						transform: scale(1.2);
						&:hover{
							z-index: 2;
							transform: translateY(-5px) scale(1.2);
							box-shadow: 0px 8px 16px #00000099;
						}}
					}
					&:first-child, &:last-child{
						background: $link;
						color: $light;
						*{
							color:$light;
						}
						.btn{
							@include button($light, $link);
							color: $base;
						}
					}
					&:hover{
						z-index: 2;
						transform: translateY(-5px);
						box-shadow: 0px 8px 16px #00000099;
					}
				}
			}
		}
	}
	&.bg{
		> .custom.bg{
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			padding-top: 48px;
			padding-bottom: 24px;
		}
	}
	&.trenner{
		padding-top: 48px;
		padding-bottom: 48px;
		.custom{
		}
	}
	&.fixedImage{
		.custom{
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			background-attachment: fixed;
			padding-top: 48px;
			padding-bottom: 24px;
		}
	}
	&.lang{
		float: right;
		margin-bottom: 24px;
	}
}