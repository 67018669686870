/* nunito-regular - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 400;
	src: url('fonts/nunito-v25-latin-regular.eot'); /* IE9 Compat Modes */
	src: url('fonts/nunito-v25-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('fonts/nunito-v25-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
	url('fonts/nunito-v25-latin-regular.woff') format('woff'), /* Modern Browsers */
	url('fonts/nunito-v25-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
	url('fonts/nunito-v25-latin-regular.svg#Nunito') format('svg'); /* Legacy iOS */
}
/* nunito-500 - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 500;
	src: url('fonts/nunito-v25-latin-500.eot'); /* IE9 Compat Modes */
	src: url('fonts/nunito-v25-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('fonts/nunito-v25-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
	url('fonts/nunito-v25-latin-500.woff') format('woff'), /* Modern Browsers */
	url('fonts/nunito-v25-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
	url('fonts/nunito-v25-latin-500.svg#Nunito') format('svg'); /* Legacy iOS */
}
/* nunito-600 - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 600;
	src: url('fonts/nunito-v25-latin-600.eot'); /* IE9 Compat Modes */
	src: url('fonts/nunito-v25-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('fonts/nunito-v25-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
	url('fonts/nunito-v25-latin-600.woff') format('woff'), /* Modern Browsers */
	url('fonts/nunito-v25-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
	url('fonts/nunito-v25-latin-600.svg#Nunito') format('svg'); /* Legacy iOS */
}
/* nunito-700 - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 700;
	src: url('fonts/nunito-v25-latin-700.eot'); /* IE9 Compat Modes */
	src: url('fonts/nunito-v25-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('fonts/nunito-v25-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
	url('fonts/nunito-v25-latin-700.woff') format('woff'), /* Modern Browsers */
	url('fonts/nunito-v25-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
	url('fonts/nunito-v25-latin-700.svg#Nunito') format('svg'); /* Legacy iOS */
}
@font-face {
	font-family: 'bare';
	src:  url('fonts/bare.eot?58a3c0');
	src:  url('fonts/bare.eot?58a3c0#iefix') format('embedded-opentype'),
	url('fonts/bare.ttf?58a3c0') format('truetype'),
	url('fonts/bare.woff?58a3c0') format('woff'),
	url('fonts/bare.svg?58a3c0#bare') format('svg');
	font-weight: normal;
	font-style: normal;
}
// base font size - adjust it to <p> size
$base-font-size: 16px;
$base-font-math: 16;
$base-font-family: 'Nunito', sans-serif;
$icon: bare;
$fontawesome: Font Awesome\ 5 Free;
/* Colors */
$base: var(--base);
$link: var(--link);
$link_hover: var(--link-hover);
$bgbox: var(--bgbox);
$main: var(--main);
$dark: var(--dark);
$light: var(--light);
$border: var(--border);
$bg: var(--bg);
$h1: var(--h1);
$h2: var(--h2);
$h3: var(--h3);
$h4: var(--h4);
$footer: var(--footer);
$header: var(--header);
$logoWidth: var(--logo-width);
$button: var(--button);
$buttonBg: var(--buttonBg);
$buttonHover: var(--buttonHover);
$buttonBgHover: var(--buttonBgHover);
$green: #3B8130;
$red: #b21f28;
// Radio/Chheckbox Styles
$md-radio-checked-color: $main;
$md-radio-border-color: $border;
$md-radio-size: 20px;
$md-radio-checked-size: 14px;
$md-radio-ripple-size: 15px;


// Styles

$br: 12px;
$box-shadow: 0 0 10px -1px #333;

// Icons
$icon-search: "\f002";
$icon-envelope-o: "\f003";
$icon-star: "\f005";
$icon-star-o: "\f006";
$icon-user: "\f007";
$icon-check: "\f00c";
$icon-close: "\f00d";
$icon-remove: "\f00d";
$icon-times: "\f00d";
$icon-cog: "\f013";
$icon-gear: "\f013";
$icon-trash-o: "\f014";
$icon-home: "\f015";
$icon-file-o: "\f016";
$icon-tag: "\f02b";
$icon-book: "\f02d";
$icon-bookmark: "\f02e";
$icon-print: "\f02f";
$icon-camera: "\f030";
$icon-pencil: "\f040";
$icon-chevron-left: "\f053";
$icon-chevron-right: "\f054";
$icon-arrow-right: "\f061";
$icon-exclamation-circle: "\f06a";
$icon-gift: "\f06b";
$icon-eye: "\f06e";
$icon-calendar: "\f073";
$icon-twitter-square: "\f081";
$icon-facebook-square: "\f082";
$icon-key: "\f084";
$icon-cogs: "\f085";
$icon-gears: "\f085";
$icon-thumbs-o-up: "\f087";
$icon-heart-o: "\f08a";
$icon-sign-out: "\f08b";
$icon-thumb-tack: "\f08d";
$icon-external-link: "\f08e";
$icon-upload: "\f093";
$icon-phone: "\f095";
$icon-phone-square: "\f098";
$icon-twitter: "\f099";
$icon-facebook: "\f09a";
$icon-facebook-f: "\f09a";
$icon-unlock: "\f09c";
$icon-credit-card: "\f09d";
$icon-hand-o-right: "\f0a4";
$icon-arrow-circle-right: "\f0a9";
$icon-globe: "\f0ac";
$icon-group: "\f0c0";
$icon-users: "\f0c0";
$icon-chain: "\f0c1";
$icon-link: "\f0c1";
$icon-floppy-o: "\f0c7";
$icon-save: "\f0c7";
$icon-truck: "\f0d1";
$icon-caret-right: "\f0da";
$icon-envelope: "\f0e0";
$icon-linkedin: "\f0e1";
$icon-rotate-left: "\f0e2";
$icon-undo: "\f0e2";
$icon-gavel: "\f0e3";
$icon-legal: "\f0e3";
$icon-comment-o: "\f0e5";
$icon-comments-o: "\f0e6";
$icon-bolt: "\f0e7";
$icon-flash: "\f0e7";
$icon-umbrella: "\f0e9";
$icon-lightbulb-o: "\f0eb";
$icon-suitcase: "\f0f2";
$icon-coffee: "\f0f4";
$icon-angle-right: "\f105";
$icon-laptop: "\f109";
$icon-tablet: "\f10a";
$icon-mobile: "\f10b";
$icon-mobile-phone: "\f10b";
$icon-quote-left: "\f10d";
$icon-quote-right: "\f10e";
$icon-folder-open-o: "\f115";
$icon-chevron-circle-right: "\f138";
$icon-instagram: "\f16d";
$icon-long-arrow-right: "\f178";
$icon-arrow-circle-o-right: "\f18e";
$icon-fax: "\f1ac";
$icon-share-alt: "\f1e0";
$icon-wifi: "\f1eb";
$icon-street-view: "\f21d";
$icon-facebook-official: "\f230";
$icon-pinterest-p: "\f231";
$icon-whatsapp: "\f232";
$icon-amazon: "\f270";
$icon-map-o: "\f278";
$icon-envira: "\f299";
$icon-telegram: "\f2c6";


// Media Queries
$small-screen: 480px !default;
$medium-screen: 768px !default;
$large-screen: 1024px !default;
$xlarge-screen: 1200px !default;
$hd-screen: 1650px !default;
$screen: "only screen" !default;

$small: "(min-width: #{$small-screen})" !default;
$small-max: "(max-width: #{$small-screen - 1})";
$medium: "(min-width:#{$medium-screen})" !default;
$medium-max: "(max-width: #{$medium-screen - 1})";
$large: "(min-width:#{$large-screen})" !default;
$large-max: "(max-width: #{$large-screen - 1})";
$xlarge: "(min-width:#{$xlarge-screen})" !default;
$xlarge-max: "(max-width: #{$xlarge-screen - 1})";
$hd: "(min-width:#{$hd-screen})" !default;
$landscape: "(orientation: landscape)" !default;
$portrait: "(orientation: portrait)" !default;


