.item-page.customer-edit {
	label {
		@include clear();
	}
}

body#com_sketchcrm {
	.chzn-container{
		a{
			background: $light;
			border: 1px solid $border;
			height: auto;
			padding: 8px 6px;
			@include font-size(16);
			div b{
				background-position: 0 10px;
			}
		}
		&.chzn-container-active {
			a div b{
				background-position: -16px 9px;
			}
		}
	}
	.jconfirm .row.jc-bs3-row {
		max-width: 100%;
		.btn {
			margin-right: 0;
		}
	}
	.chzn-container{
		margin-bottom: 24px;
	}
	.btn {

		span[class*='icon']:not(.icon-plus-2):before {
			margin-right: 6px;
			font-size: 80%;
			font-weight: normal;
		}
		&:hover:after {
			background: $dark;
		}
	}
	.formattedCustomerData{
		ul{
			list-style: none;
			margin-left: 0;
			li{
				padding-left: 0;
				&:before{
					display: none;
				}
			}
		}
	}
	.customerOwner{
		ul{
			list-style: none;
			margin-left: 0;
			li{
				padding-left: 0;
				&:before{
					display: none;
				}
			}
		}
	}
	.document.tableList table th{
		background: #eee;
		color: $base;
	}
	.documentTypes{
		td{
			color: $base;
			font-weight: 500;
			padding: 6px;
			border: 1px solid #eee;
		}
		td.actions{
			.btn{
				@include font-size(14);
				padding: 3px 7px;
				span{
					margin-right: 6px;
				}
			}
		}
	}
	.uploadCenter {
		.dropzone.dz-clickable {
			margin: 0 0 24px !important;
			max-width: 100%;
			width: 100%;
		}
	}
	.customerOwner{
		ul{
			list-style: none;
			margin-left: 0;
		}
	}
	.qrCode{
		margin-bottom: 24px;
	}
	.payNow{
		span.btn.custom{
			margin-left: 12px;
		}
	}
}
dl.oposFormatted{
	margin-bottom: 24px;
	@include clear();
	dt{
		text-align: right;
		padding-right: 12px;
		font-weight: 500;
	}
	dd{
		font-weight:500;
		padding-left: 12px;
	}
	dt, dd{
		width: 50%;
		float: left;
		display: block;
	}
}
button.btn.btn-small.btn-success.addAddressBookEntry {
	width: 50px;
	height: 50px;
	line-height: 54px;
	font-size: 20px;
	text-align: center;
	padding: 0;
	z-index: 1;
	border-radius: 100%;
	> span.icon-plus-2{
		margin: 0;
		width: auto;
		height: auto;
	}
}
.item-page.documents-default{
	.uploadCenter{
		.dropzone.dz-clickable{
			background: #eee;
			border: 2px solid #adadad!important;
		}
		button.dz-button{
			@include button($base );
		}
	}
}
textarea.freetext {
	width: 646px;
	max-width: 100%;
	min-height: 220px;
	max-height: 100%;
}
.addressForm > form hr {
	display: none;}