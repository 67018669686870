.blog, .blog-featured {
	@include clear();
	.blog-items {
		display: flex;
		flex-wrap: wrap;
		margin-left: -12px;
		margin-right: -12px;
		@media #{$large}{
			margin-left: -24px;
			margin-right: -24px;
		}
		&.masonry-2 {
			.blog-item {
				width: 100%;
				@media #{$medium}{
					width: 50%;
				}
			}
		}
		&.masonry-3 {
			.blog-item {
				width: 100%;
				@media #{$large}{
					width: 33.333333%;
				}
			}
		}
		&.masonry-4 {
			.blog-item {
				width: 100%;
				@media #{$medium}{
					width: 50%;
				}
				@media #{$large}{
					width: 25%;
				}
			}
		}
		.blog-item {
			padding-left: 12px;
			padding-right: 12px;
			margin-bottom: 24px;
			transition: .3s all ease 0s;
			@media #{$large}{
				padding-left: 24px;
				padding-right: 24px;
				margin-bottom: 48px;
			}
			.itemWrap {
				background: $bgbox;
				padding: 12px;
				height: 100%;
				position: relative;
				@media #{$large}{
					padding: 24px;
				}
			}
		}
	}
	.items-row {
		@include clear();
		margin-bottom: 48px;
		&:nth-child(even) {
			figure.item-image, div.item-image {
				&.left, &.pull-left {
					&:before {
						background: $main;
					}
					float: right;
					margin-left: 48px;
					margin-top: 0;
					position: relative;
					padding-right: 6px;
					padding-top: 0;
					margin-bottom: 24px;
					img {
						box-shadow: 0px 3px 6px #00000099;;
						position: relative;
						margin-bottom: -12px;
						bottom: -21px;
						display: inline-block;
						margin-left: -24px;
						transition: all .3s cubic-bezier(.2, .5, .3, 1);
						margin-top: 0;
						margin-right: 0;
					}
				}
				&:before {
					position: absolute;
					box-shadow: 0px 3px 6px #00000099;
					top: 0;
					left: 12px;
					right: 0;
					bottom: 0;
					content: '';
					background: $link;
				}
				&:hover img {
					transform: translateY(-5px);
					box-shadow: 0px 8px 16px #00000099;
				}
			}
		}
	}
}

.category-list {
	@include clear();
}