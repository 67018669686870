body, html {
	font-size: var(--base-font-size);
	height: 100%;
}

// Sticky Footer
body {
	display: flex;
	flex-direction: column;
}

.allwrap {
	flex: 1 0 auto;
}

footer {
	flex-shrink: 0;
}

// end Sticky Footer
* {
	@include font-size(16);
	font-family: $base-font-family;
}

body {
	background-color: $bg;
	background-repeat: no-repeat;
	background-size: cover;
}

.allwrap {
	overflow: hidden;
}

header {
	width: 100%;
	position: relative;
	z-index: 1;
	background-color: $header;
	.row.flex {
		> .columns {
			margin-bottom: 0;
			align-items: center;
			display: flex;
			&.right {
				justify-content: right;
				align-items: flex-end;

			}
		}
	}
	.logo {
		img {
			display: block;
			max-width: 100%;
			margin-top: 12px;
			width: $logoWidth;
		}
		float: left;
		margin-bottom: 6px;
	}
	.top_right {
		.moduletable.right {
			float: right;
			@media #{$large}{
				border-left: 3px solid $link;
			}
			padding-top: 12px;
			padding-left: 24px;
			margin-top: 12px;
			max-height: 54px;
			@media #{$hd}{
				padding-left: 48px;
			}
			padding-bottom: 12px;
			a {
				@include font-size(20);
				&.icon-envelope {
					@include icon($icon-envelope, none);
				}
			}
		}
		.menuwrap {
			float: right;
			@media #{$hd}{
				margin-right: 24px;
			}
		}
	}
	&.isFixed {
		position: fixed;
		z-index: 11;
		transition: .5s all ease 0s;
		.logoRow {
			overflow: hidden;
			max-height: 400px;
			transition: .5s max-height ease 0s;
		}
		&.solid {
			min-height: 0;
			box-shadow: 0px 3px 6px #00000043;
			background-color: $header;
			.logoRow {
				max-height: 0;
			}
		}
	}
}
.topRow{
	background-color: $main;
	p{
		margin-bottom: 0;
	}
	*{
		color: $light;
	}
}
.alignRight{
	text-align: right;

}
main {
	padding-top: 24px;
	padding-bottom: 48px;
}
.sketchwrap_slider:empty+main{
	min-height: 734px;
}

body.home main {
	padding-bottom: 0;
}
body.full {
	main section.sketchwrap_content_main{
		> .row{
			max-width: 100%;
		}
	}
}
footer {
	width: 100%;
	float: left;
	clear: both;
	background: $footer;
	padding-top: 24px;
	padding-bottom: 24px;
	.moduletable.slidenav2 {
		float: left;
	}
}

.match {
	transition: none !important;
}

body .cc_banner-wrapper:hover {
	a.cc_more_info {
		color: $main !important;
	}
	a.cc_btn.cc_btn_accept_all {
		background: $main !important;
		color: $light !important;
	}
}

.top_top {
	position: fixed;
	bottom: 0;
	width: 100%;
	box-shadow: 0 -1px 1px 0 rgba(50, 50, 50, .5);
	height: 5px;
	background: $footer;
	text-align: center;
	z-index: 2;
	&.stick {
		position: relative;
		bottom: 0 !important;
	}
	button.scroll_to_top {
		background: $footer;
		display: inline-block;
		height: 45px;
		position: relative;
		width: 45px;
		top: -44px;
		box-shadow: 0 -1px 1px 0 rgba(50, 50, 50, .5);
		z-index: 2;
		border-radius: 100% 100% 0 0;
		span.red_arrow {
			background: $light;
			color: $footer;
			display: block;
			width: 29px;
			height: 29px;
			border-radius: 100%;
			font-weight: lighter;
			line-height: 35px;
			font-size: 28px;
			margin: 0 auto;
			transition: .3s all ease 0s;
			@include icon($icon-angle-right, none);
			&:hover {
				background: $footer;
				color: $light;
			}
			&:before {
				transform: rotate(-90deg);
				display: block;
				transform-origin: center;
				padding-left: 6px;
			}
		}
	}
}

a.cc_more_info {
	font-size: inherit;
}

@media (prefers-color-scheme: dark) {
	:root {
		color-scheme: light !important;
	}
}

.visually-hidden {
	display: none;
}


// GDPR fixes

body div.cc-checkboxes-container a:not(.cc-btn) {
	margin: 0 0 0 2px;
	padding: 3px 6px 0 0;
	text-decoration: none;
	padding-top: 0;
	position: relative;
	top: -4px;
	padding-left: 4px;
	border: 0 !important;
}
body a.cc-link label{
	@include font-size(14);
}
body a.cc-btn.cc-allow {
	@include button();
	@include font-size(14);
	line-height: 16px;
}
body div.cc-settings-label {
	font-weight: normal;
	@include font-size(18);
	margin-bottom: 12px;
}

.colorbox{
	@include bgfull($link);
	margin-bottom: 48px;
	display: flex !important;
	.wf-column{
		background: $light;
		padding: 12px;
		@media #{$large}{
			padding: 24px;
		}
	}
	&.dark{
		@include bgfull($base);
	}
	&.main{
		@include bgfull($main);
	}
}
