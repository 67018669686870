div.top_right {
	.moduletable {
		ul.nav {
			margin-top: 10px;
			margin-bottom: 10px;
			float: right;
			li {
				display: inline;
				list-style: none;
				margin-left: 12px;
				@media #{$xlarge}{
					margin-left: 24px;
				}
				&:first-child{
					margin-left: 0;
				}
				a {
					@include font-size(12);
				}
			}
		}
	}
}

nav.top_menu {
	.moduletable {
		float: left;
		overflow: visible;
		&.slidenav1{
			padding: 0;
		}
		ul.nav.menu {
			margin-left: 0;
			margin-bottom: 0;
			> li {
				display: inline-block;
				list-style: none;
				position: relative;


				&.parent{
					> a, > span{
						&:after{
							content: '';
							font-family: monospace;
							position: absolute;
							transform: rotate(90deg) translate(2px, 50%);
							left: 50%;
							width: 0;
							height: 0;
							border-top: 6px solid transparent;
							border-bottom: 6px solid transparent;
							border-left: 6px solid $link;
							display: block;
						}
					}
				}
				> a, > span {
					color: $base;
					margin: 17px 15px 0;
					transition: .6s all ease 0s;
					text-decoration: none;
					display: block;
					@include font-size(16);
					font-weight: 500;
					padding-bottom: 29px;
					position: relative;
					cursor: pointer;
					@media #{$xlarge}{
						margin: 17px 20px 0;
					}
					&:before{
						content:'';
						position: absolute;
						display: block;
						top: -12px;
						left: 0;
						width: 0;
						height: 3px;
						background: $link;
						color: $light;
						transition: .3s all ease 0s;
					}
				}
				&:hover {

						> a, > span{
							&:before{
								width: 100%;
							}
						}

				}
				ul.nav-child{
					position: absolute;
					top: 150%;
					left: 0;
					transition: .5s all ease 0s;
					margin-left: 0;
					width: auto;
					overflow: hidden;
					visibility: hidden;
					opacity: 0;
					z-index: 99;
					min-width: 100%;
					box-shadow: 0px 19px 20px -20px #000;
					li{
						display: block;
						float: none;
						margin: 0;
						list-style: none;
						position: relative;
						&:first-child a{
							border-left: 0;
						}
						a{
							@include font-size(14);
							padding: 12px 10px;
							margin: 0;
							background: $header;
							text-transform: none;
							letter-spacing: 0;
							font-family: $base-font-family;
							font-weight: 500;
							color: $base;
							border-right: 0;
							display: block;
							&:after{
								content:'';
							}
							&:first-child{
								border-left: 0;
							}
						}
						&:hover{
							> a{
								background-color: $link_hover;
								color: $base;
							}
						}
					}
				}
				&:hover{
					ul.nav-child{
						opacity: 1;
						display: block;
						visibility: visible;
						top: 100%;
						z-index: 100;
					}
				}
				&.active{
					>a, > span{
						color: $link;
					}
					ul {
						li{
							a{
							}
						}
					}
				}
				&:last-child > a{
					margin-right: 0;
				}
			}
		}
	}
}

nav.bottom {
	.moduletable {
		ul.nav {
			margin-left: 0;
			li {
				display: inline;
				list-style: none;
				margin-right: 12px;
				@media #{$xlarge}{
					margin-right: 24px;
				}
				&:last-child{
					margin-right: 0;
				}
				a {
					@include font-size(14);
					color: $light;
				}
				a:hover{
					color: $link_hover;
				}
			}
		}
	}
}
.slidenav1, .slidenav2, .slidenav3, .slidenav4{
	display: none;
	@media #{$large}{
		display: block;
	}
}
/* Icon 4 */

#main-nav {
	width: 60px;
	height: 45px;
	position: relative;
	margin: 0 auto 18px;
	transform: rotate(0deg);
	transition: .5s ease-in-out;
	cursor: pointer;
	float: left;
	display: block;
	@media #{$large}{
		display: none;
	}
	span, &:before, &:after{
		display: block;
		position: absolute;
		height: 9px;
		width: 100%;
		background: $link;
		border-radius: 9px;
		opacity: 1;
		left: 0;
		transform: rotate(0deg);
		transition: .25s ease-in-out;
	}
	&:before {
		top: 0px;
		content: '';
		transform-origin: left center;
	}
	&:after {
		content:'';
		top: 18px;
		transform-origin: left center;
	}
	span {
		top: 36px;
		transform-origin: left center;
	}
	&.open:before {
		transform: rotate(45deg);
		top: -3px;
		left: 8px;
	}
	&.open:after {
		width: 0;
		opacity: 0;
	}
	&.open span {
		transform: rotate(-45deg);
		top: 39px;
		left: 8px;
	}
	&.open{
		span, &:before, &:after{
			background: $link_hover;
		}
	}
}
nav.top_menu .moduletable ul.nav.menu ul.nav-child li.active.active>a:before{
	 display: none;
 }
