.sketchwrap_slider {
	overflow: hidden;
	.sketchslider_pro-wrap {
		.sketchslider_pro {
			margin-bottom: 30px;
			.box {
				background: transparent;
				color: $light;
				text-shadow: 0 0 3px #333;

				position: relative;
				left: auto;
				right: auto;
				bottom: auto;
				top: auto;
				@media #{$large}{
					position: absolute;
					bottom: 48px;
					left: 12px;
				}
				@include font-size(20);
				@media #{$hd}{
					@include font-size(32);
				}
				span.slider_headline {
					@include font-size(40, 1.3);
					@media #{$hd}{
						@include font-size(54, 1.2);
					}
					font-weight: 700;
					text-shadow: 0px 3px 6px #0000008C;
				}
				* {
					color: $light;
				}
				.btn {
					margin-top: 48px;
					@include font-size(16);
				}
			}
			ul.slides {
				> li {
					.columns {
						min-height: 0;
					}
					&:before {
						content: '';
						position: absolute;
						top: 0;
						right: 0;
						left: 0;
						bottom: 0;
						background: rgba(0, 0, 0, .3);
						z-index: 2;
					}
					.box {
						z-index: 3;
					}
					img {
						z-index: 1;
					}
				}
			}
		}
	}
}
